import React from 'react';

const Loader = () => {
  return (
    <main>
      <div className="loader-main">
        <div className="loader-container">
          <div className='img'>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Loader;